import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-preview-image-modal',
  templateUrl: './preview-image-modal.page.html',
  styleUrls: ['./preview-image-modal.page.scss'],
})
export class PreviewImageModalPage implements OnInit {

  image;

  constructor(
    private modalController: ModalController,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss()
  }

}
