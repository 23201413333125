import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.page.html',
  styleUrls: ['./image-preview.page.scss'],
})
export class ImagePreviewPage implements OnInit {
  
  // imageLoaded: boolean = true;
  // hideSpinner: boolean = false;
  hideSpinner: boolean
  modalData;
  data;
  imgListLen;
  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    // console.log(this.modalData);
    try{
      this.imgListLen = this.modalData.images.length;
    }catch(err){

    }
    
  }



  imgLoaded(){
    // console.log('Image loaded!');
    this.hideSpinner = true;
  }

  ngAfterViewInit(){
    this.slides.lockSwipes(true);
  }
  
  @ViewChild('mySlider')  slides: IonSlides;

  dismissModal(action: string){
    this.modalController.dismiss(action);
  }

  ActionType(action: string){
    this.dismissModal(action);
  }


  nextInvoice(){
    this.slides.lockSwipes(false);
    this.slides.slideNext();
    this.slides.lockSwipes(true);
  }

  previousInvoice(){
    this.slides.lockSwipes(false);
    this.slides.slidePrev();
    this.slides.lockSwipes(true);
  }
}
