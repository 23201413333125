import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-invoice-slider',
  templateUrl: './invoice-slider.component.html',
  styleUrls: ['./invoice-slider.component.scss'],
})
export class InvoiceSliderComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit(){
    this.slides.lockSwipes(true);
  }

  @Input() data;
  
  @ViewChild('mySlider')  slides: IonSlides;


  nextInvoice(){
    this.slides.lockSwipes(false);
    this.slides.slideNext();
    this.slides.lockSwipes(true);

  }

  previousInvoice(){
    this.slides.lockSwipes(false);
    this.slides.slidePrev();
    this.slides.lockSwipes(true);

  }

}
