import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardService } from './services/authguard/authguard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'onboarding',
    pathMatch: 'full'
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./mvp/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
 
  {
    path: 'tabs-page',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./mvp/tabs-page/tabs-page.module').then( m => m.TabsPagePageModule)
  },
  {
    path: 'rootTabs',
    canActivate:[AuthguardService],
    loadChildren: () => import('./shared/tabs/tabs.module').then( m => m.TabsPageModule)
  },
 
  {
    path: 'custom-camera',
    loadChildren: () => import('./visibility/custom-camera/custom-camera.module').then( m => m.CustomCameraPageModule)
  },
  {
    path: 'dashboard',
    // canActivate:[AuthguardService],
    loadChildren: () => import('./mvp/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'enrollment',
    loadChildren: () => import('./mvp/enrollment/enrollment.module').then( m => m.EnrollmentPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./mvp/users/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'profilepage',
    loadChildren: () => import('./mvp/users/profilepage/profilepage.module').then( m => m.ProfilepagePageModule)
  },
  {
    path: 'leaderboard',
    loadChildren: () => import('./mvp/users/leaderboard/leaderboard.module').then( m => m.LeaderboardPageModule)
  },
  {
    path: 'pointledger',
    loadChildren: () => import('./mvp/users/pointledger/pointledger.module').then( m => m.PointledgerPageModule)
  },
  {
    path: 'productand-pointcalculation',
    loadChildren: () => import('./mvp/productand-pointcalculation/productand-pointcalculation.module').then( m => m.ProductandPointcalculationPageModule)
  },
  {
    path: 'e-brochure',
    loadChildren: () => import('./mvp/e-brochure/e-brochure.module').then( m => m.EBrochurePageModule)
  },
  {
    path: 'catalogue',
    loadChildren: () => import('./mvp/catalogue/catalogue.module').then( m => m.CataloguePageModule)
  }



];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
