import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ApiConfiguration } from "../../../../services/http/api-configuration";
import { ApiService } from "../../../../services/http/api.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rejection-action-modal',
  templateUrl: './rejection-action-modal.page.html',
  styleUrls: ['./rejection-action-modal.page.scss'],
})
export class RejectionActionModalPage implements OnInit {

  formGroup;
  userData;
  hide = {
    hideChallenge: false,
    hideDetails: true
  }
  reasons: any = []
  userDetails
  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private modalController: ModalController,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToasterService,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {
    let localData = localStorage.getItem('login-details')
    this.userDetails = JSON.parse(localData)
  }

  ngOnInit() {

    this.formGroup = this.fb.group({
      user_id: this.userData.user_id,
      reason_id: new FormControl("", [Validators.required]),
      comment: new FormControl("", [Validators.required])
    });
    // console.log('md', this.userData);
  }

  get f() {
    return this.formGroup.controls
  }

  resubmitEnrollment() {
    this.modalController.dismiss()
    // self enrollment flow
    //check if self enrollment
    if (this.userDetails.is_self_enrolled) {
      // check if it's either pending or rejected
      if (this.userDetails.enrollment_status == "Pending" || this.userDetails.enrollment_status == "Rejected") {
        return this.router.navigateByUrl(`/onboarding/self-enrollment/${this.userDetails.user_id}`)
      }
    }

    // App enrollment flow
    if (this.userData.enrollment_type == 1) {
      this.router.navigateByUrl('/tabs-page/enrollment/app-enrollment/' + this.userData.user_id)
    } else if (this.userData.enrollment_type == 2) {
      this.router.navigateByUrl('/tabs-page/enrollment/online-brochure/' + this.userData.user_id)
    }
  }

  challengeRejection() {
    this.hide.hideChallenge = true;
    this.hide.hideDetails = false;

    this.apiService.get(this.apiConfig.getReasons)
      .subscribe((res: any) => {
        this.reasons = res.data.reason_list
        //console.log('reasons', this.reasons);
      }, err => {
        // console.log(err)
      });
  }

  submitted = false;

  confirmResubmit(formData) {
    this.submitted = true;
    if (this.formGroup.invalid) {
      return;
    }

    this.apiService.post(this.apiConfig.challengeRejection, formData)
      .subscribe((res: any) => {
        this.toast.presentToast(this.translateService.instant('enrollment_status.challenge_success'));
        // this.hide.hideChallenge = false; 
        // this.hide.hideDetails = true;
        this.modalController.dismiss('update')
      }, err => {
        this.toast.presentToast(err.data.message)
      })
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
