import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss'],
})
export class TaskStatusComponent implements OnInit {

  @Input() data;
  @Output() doTask = new EventEmitter();
  constructor() { }

  ngOnInit() {}
  task(data){
    this.doTask.emit(data);
  }

}
