import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EnrollmentStatusPageRoutingModule } from './enrollment-status-routing.module';

import { EnrollmentStatusPage } from './enrollment-status.page';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MaterialModule } from 'src/app/utils/sharedModules/material.module';
import {MatTabsModule} from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EnrollmentStatusPageRoutingModule,
    Ng2SearchPipeModule,
    MaterialModule,
    MatTabsModule,
    TranslateModule
  ],
  declarations: [EnrollmentStatusPage]
})
export class EnrollmentStatusPageModule {}
