import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-target-details-modal',
  templateUrl: './target-details-modal.page.html',
  styleUrls: ['./target-details-modal.page.scss'],
})
export class TargetDetailsModalPage implements OnInit {

  receivedData: any = {};

  constructor(
    private modalController: ModalController,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    //console.log('rd', this.receivedData)
  }

  dismiss() {
    //console.log('asd');
    this.modalController.dismiss()
  }

}
