import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpConfigInterceptor } from './services/interceptors/http-loading-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './utils/sharedModules/material.module';
import { TargetDetailsModalPageModule } from "./targets-achievements/target-details-modal/target-details-modal.module";
import { EnrollmentStatusPageModule } from "./mvp/enrollment/enrollment-status/enrollment-status.module";
import { PreviewImageModalPageModule } from "./mvp/enrollment/preview-image-modal/preview-image-modal.module";
import { RewardVerificationModalPageModule } from "./mvp/rewards/reward-verification-modal/reward-verification-modal.module";
// import { AddAddressModalPageModule } from "./eCommerce/add-address-modal/add-address-modal.module";
import { ImagePreviewPageModule } from "./components/image-preview/image-preview.module";
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthguardService } from './services/authguard/authguard.service';
import { SharedModule } from './shared/shared.module';
import { IonicStorageModule } from '@ionic/storage';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { StreamingMedia, StreamingVideoOptions } from '@ionic-native/streaming-media/ngx';
import { AuthenticationService } from './services/authguard/authentication.service';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { TranslateFakeLoader, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Market } from '@ionic-native/market/ngx';
import { IonicSelectableModule } from 'ionic-selectable';
import { FCM } from '@ionic-native/fcm/ngx';
import { LanguageService } from './services/language/language.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { AnalyticsProvider } from 'providers/analytics/analytics';

import { ComponentsModule } from './components/components.module';
 
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
   ],
  entryComponents: [],
  imports: [BrowserModule,
    IonicModule.forRoot({
      swipeBackEnabled: false,
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    IonicSelectableModule,
    ReactiveFormsModule,
    CommonModule,
    EnrollmentStatusPageModule,
    PreviewImageModalPageModule,
    RewardVerificationModalPageModule,
    ComponentsModule,
    // vanilla
    TargetDetailsModalPageModule,
    SharedModule,
    ImagePreviewPageModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })

  ],
  providers: [
    StatusBar,
    FCM,
    FirebaseAnalytics,
    AnalyticsProvider,
    SplashScreen,
    AuthguardService,
    AuthenticationService,
    AndroidPermissions,
    Camera,
    BarcodeScanner,
    ScreenOrientation,
    YoutubeVideoPlayer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    StreamingMedia,
    CameraPreview,
    Geolocation,
    Market,
    AppVersion,
    InAppBrowser,
    Diagnostic,
    LanguageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
