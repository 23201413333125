import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-search',
  templateUrl: './date-search.component.html',
  styleUrls: ['./date-search.component.scss'],
})
export class DateSearchComponent implements OnInit {
  
  @Output() searchResult = new EventEmitter();
  
  term = "";
  constructor() { }

  ngOnInit() {}

  emitEvent(text){
    // console.log(text);
    this.searchResult.emit(text);
  }

}
