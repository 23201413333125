import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiConfiguration } from "../../../services/http/api-configuration";
import { ApiService } from "../../../services/http/api.service";
import { ToasterService } from "../../../services/toaster/toaster.service";
import { RejectionActionModalPage } from "./rejection-action-modal/rejection-action-modal.page";
import { AlertController } from '@ionic/angular';
import { PreviewImageModalPage } from "../preview-image-modal/preview-image-modal.page";
import * as moment from 'moment/moment.js';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-enrollment-status',
  templateUrl: './enrollment-status.page.html',
  styleUrls: ['./enrollment-status.page.scss'],
})

export class EnrollmentStatusPage implements OnInit {

  statusText: String = 'approved';
  beats: any = [];
  outlets: any = [];
  beatId: any;
  enrollmentData: any = {}
  term;
  graphValue;
  className;
  selectedRejectEnrollment: any;

  userConfigDetails: any;
  hasBeat: any;
  hasChannel: any;

  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private toast: ToasterService,
    private modalController: ModalController,
    public alertController: AlertController,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {
    this.userConfigDetails = JSON.parse(localStorage.getItem('login-details'));
    this.hasBeat = this.userConfigDetails.has_beat;
    this.hasChannel = this.userConfigDetails.has_channel;
    //console.log("user config = ", this.hasBeat)
  }

  ngOnInit() {

  }


  ionViewDidEnter() {
    this.getBeats();
    this.beatId = ''
  }

  showStatus(text, className) {
    this.statusText = text
    this.className = className
    // graph-sec
    let element = document.getElementById('tile-row')
    element.removeAttribute('class')
    var att = document.createAttribute("class");
    att.value = `${className} md hydrated`;
    element.setAttributeNode(att);
  }

  getBeats() {
    this.apiService.get(this.apiConfig.getBeats)
      .subscribe((res: any) => {
        this.beats = res.data.beats
        //this.beats.unshift({ id: "", beat: "All" })
        this.getOutlets();
      }, err => {
        this.toast.presentToast(err.data.message)
      });
  }

  beatSelected(e) {
    this.beatId = e
    this.getOutlets();
  }

  getOutlets() {
    let url
    if (this.beatId == undefined) {
      url = this.apiConfig.getOutletsOnBeat
    } else {
      url = this.apiConfig.getOutletsOnBeat + this.beatId
    }

    this.apiService.get(url)
      .subscribe((res: any) => {
        this.enrollmentData = res.data
        // calculate %
        let enrollValue = (this.enrollmentData.total_enrolled_count / this.enrollmentData.total_outlets_count) * 100;
        this.graphValue = Math.round(enrollValue);
        // Approved date format changed
        if (this.enrollmentData.total_approved_users.length > 0) {
          this.enrollmentData.total_approved_users.forEach(outlet => {
            outlet.enrollment_date = moment(outlet.enrollment_date).format("LL");
            outlet.status_changed_date = moment(outlet.status_changed_date).format("LL");
          });
        }

        // Rejected date format changed
        if (this.enrollmentData.total_rejected_users.length > 0) {
          this.enrollmentData.total_rejected_users.forEach(outlet => {
            outlet.enrollment_date = moment(outlet.enrollment_date).format("LL");
            outlet.status_changed_date = moment(outlet.status_changed_date).format("LL");
          });
        }

        //console.log(this.enrollmentData)
      }, err => {
        // console.log(err)
      });
  }

  async enlargeImage(data) {
    let image = data.store_board
    // let image = 'https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80';
    const modal = await this.modalController.create({
      component: PreviewImageModalPage,
      componentProps: {
        image: image,
      }
    });

    modal.onDidDismiss()
      .then((data) => {
      });

    return await modal.present();
  }

  async openRejectionModal(data) {
    this.selectedRejectEnrollment = data
    if (this.selectedRejectEnrollment.reject_count > 2) {
      this.limitAlert();
    } else {
      const modal = await this.modalController.create({
        component: RejectionActionModalPage,
        cssClass: 'tansparent-modal',
        componentProps: {
          userData: data,
        },
        backdropDismiss: true,
      });

      modal.onDidDismiss()
        .then((data) => {
          if (data.data == 'update') {
            this.ChallengedAlert();
          }
        });

      return await modal.present();
    }
  }

  async ChallengedAlert() {
    const alert = await this.alertController.create({
      cssClass: 'cargill-alert t2',
      subHeader: this.translateService.instant('enrollment_status.challenge_text')`${this.selectedRejectEnrollment.ro_name}`,
      buttons: ['Close'],

      backdropDismiss: false
    });

    await alert.present();

    await alert.onDidDismiss().then(() => this.getBeats());
  }

  async limitAlert() {
    const alert = await this.alertController.create({
      cssClass: 'cargill-alert t2',
      subHeader: this.translateService.instant('enrollment_status.enrollment_limit'),
      buttons: [this.translateService.instant('common.close')],

      backdropDismiss: false
    });

    await alert.present();

    // await alert.onDidDismiss().then(()=> this.getBeats());
  }

}
