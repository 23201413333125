import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent implements OnInit {
  @Input() buttonName:any;
  @Input() type:any;
  @Input() height:any;
  @Input() border_radius:string;
  @Input() disabled:boolean;
  // @Input() buttonColor: boolean;

  constructor() { }

  ngOnInit() {}

}
