import { Component, Input, OnInit, NgZone } from '@angular/core';
import { ModalController, ActionSheetController } from '@ionic/angular';
import { MediaServiceService } from 'src/app/services/mediaService/media-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contest-image-preview',
  templateUrl: './contest-image-preview.component.html',
  styleUrls: ['./contest-image-preview.component.scss'],
})
export class ContestImagePreviewComponent implements OnInit {
  @Input() contestImg: any;
  private mediaSubscribtion: Subscription;
  constructor(
    private modalController: ModalController,
    private mediaService: MediaServiceService,
    public actionSheetController: ActionSheetController,
    private zone: NgZone
  ) { }

  ngOnInit() {
    console.log('contestImg', this.contestImg);
  }

  close(type:any='') {
    this.modalController.dismiss({type: type, img: this.contestImg});
  }

  /**
   * 
   * Open action sheet for profile section options.
   */
  async PicActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Contest Image',
      buttons: [{
        text: 'Gallery',
        // role: 'destructive',
        icon: 'images',
        handler: () => {
          //console.log('Gallery');
          this.openGallery();
        }
      }, {
        text: 'Camera',
        icon: 'camera',
        handler: () => {
          // console.log('camera');
          this.openCamera();
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          //console.log('Cancel clicked');

        }
      }]
    });
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    // console.log('onDidDismiss resolved with role', role);
  }
  /**
   * Open Gallery images
   */
  async openGallery() {
    this.mediaService.gallery();
    this.mediaSubscribtion = this.mediaService.returnImage.subscribe(res => {
      console.log('gallery', res)
      if(res != 'cancelled') {
        this.zone.run(()=>{
          this.contestImg = res;
        });
      
      this.callUnsubscribeFunction();
      }
    })
  }
  /**
   * Open camera 
   */
  async openCamera() {
    this.mediaService.captureImage();
    this.mediaSubscribtion = this.mediaService.returnImage.subscribe(res => {
      console.log('camera', res)
      if(res != 'cancelled') {
      this.zone.run(()=>{
        this.contestImg = res;
      });
      
      this.callUnsubscribeFunction();
      }
    })
  }
  /**
 * Cancel the media subscription
 */
  callUnsubscribeFunction() {
    this.mediaSubscribtion.unsubscribe();
  }

}
