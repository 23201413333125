import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ImagePreviewPageRoutingModule } from './image-preview-routing.module';

import { ImagePreviewPage } from './image-preview.page';

// import { InvoiceSliderComponent } from "../../components/invoice-slider/invoice-slider.component";
// import { IonicImageLoader } from 'ionic-image-loader';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ImagePreviewPageRoutingModule,
    // IonicImageLoader
  ],
  declarations: [ImagePreviewPage, 
    // InvoiceSliderComponent
  ] 
})
export class ImagePreviewPageModule {}
