import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimaryButtonComponent } from '../components/primary-button/primary-button.component';
import { NgOtpInputModule } from  'ng-otp-input';
@NgModule({
  imports: [
    CommonModule,
    NgOtpInputModule
  ],
  declarations: [PrimaryButtonComponent],
  // providers: [MySharedService],
  exports: [PrimaryButtonComponent,NgOtpInputModule],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
