import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-orders-card',
  templateUrl: './orders-card.component.html',
  styleUrls: ['./orders-card.component.scss'],
})
export class OrdersCardComponent implements OnInit {

  @Input() data;
  @Output() addcart = new EventEmitter;
  @Output() cartSummeryQty = new EventEmitter;
  @Output() removeFromCart = new EventEmitter;

  qtydata = [1,2,3,4,5,6,7,8,9,10];

  selected = {
    qty:1,
  };

  addedData;

  constructor() { }

  ngOnInit() {
    if(this.data.iscartSummery){
      this.selected.qty = this.data.quantity;
    } else{
      this.selected.qty = this.data.quantity_in_cart;
    }
  }

  qtyChange(eve,data){
    if(this.data.iscartSummery){
      this.data.quantity = eve;
      this.cartSummeryQty.emit(this.data);
      
    }
    this.selected.qty = eve;
  }

  addtoCart(data){
    this.addedData = data;
    if(!this.selected.qty || this.selected.qty == 0){
      return;
    }
    data.qty = this.selected.qty;
    this.addcart.emit(data);
  }

  removeCart(data){
    this.removeFromCart.emit(data);
  }
  
}
