import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiConfiguration } from "../../services/http/api-configuration";
import { ApiService } from "../../services/http/api.service";
@Component({
  selector: 'app-cardlist',
  templateUrl: './cardlist.component.html',
  styleUrls: ['./cardlist.component.scss'],
})
export class CardlistComponent implements OnInit {
  @Input() cardData:any;
  @Input() orderData:boolean;
  @Input() hideBtn:boolean;
  @Output() removecart = new EventEmitter();
  @Output() totalPointItem = new EventEmitter<string>();
  @Output() balancePointItem = new EventEmitter<string>();
  qtyselect
  qtydata = [1,2,5,10];
  selected = {
    qty:1,
    checked:false
  };
  totalPoints
  constructor( private apiConfig: ApiConfiguration , private apiService: ApiService,) { }
  removeItem(id){
    this.removecart.emit(id);
  }
  ngOnInit() {
    
  }
  qtyChange(ev){
    this.selected.qty = ev;
    let points=this.cardData.points?this.cardData.points==undefined:0
    // console.log('cardData',this.cardData)
     let data={
      reward_customization:JSON.stringify(this.cardData.reward_customization),
      product_id:this.cardData.id,
      product_points:this.cardData.points,
      denomination:this.cardData.denominations,
      qty:ev
     }
    let url=this.apiConfig.baseUrlNew + this.apiConfig.productquantityupdate
    this.apiService.post(url,data)
    .subscribe((res:any)=>{
      this.totalPoints=res.data.total_points
      // console.log('totalPoints',this.totalPoints)
      this.totalPointItem.emit(res.data.total_points);
      this.balancePointItem.emit(res.data.balance_points)
    })
  }
 
}
