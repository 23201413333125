import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-layout-values',
  templateUrl: './card-layout-values.component.html',
  styleUrls: ['./card-layout-values.component.scss'],
})
export class CardLayoutValuesComponent implements OnInit {
  @Input() header;
  @Input() content;
  @Input() font_size:number;

  constructor() { }

  ngOnInit() {
    // console.log(this.header);
  }

}
