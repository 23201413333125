import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';
import { Observable as __Observable, throwError, from } from 'rxjs';
import { map as __map, filter as __filter, catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient, protected config: ApiConfiguration) { }

  // If we want to access mock data from local
  public getsMockData(filename: string): __Observable<any> {
    return this.httpClient.get("assets/mock/" + filename + ".json",{responseType: 'text'});
  }

  // Get details from server
  public get(url: any): __Observable<[]> {
    return this.httpClient.get<[]>(this.check_program_url(url), {responseType: 'json'})
    .pipe(catchError(this.handleError))
  }

  // post details to server
  public post(url,data:any):__Observable<any>{
    let headers = { 'Content-Type':'application/json' }; 
    return this.httpClient.post<any>(this.check_program_url(url, data), this.check_program_data(url, data), {headers,responseType: 'json'}).pipe(catchError(this.handleError))
  }

   // post with without program_id
   public postWithoutProgrmid(url,data:any):__Observable<any>{
    let headers = { 'Content-Type':'application/json' }; 
    return this.httpClient.post<any>(url, data, {headers,responseType: 'json'}).pipe(catchError(this.handleError))
  }
  public postWithFormData(url,data:any):__Observable<any>{
    let headers = { 'Content-Type':'application/json' }; 
    return this.httpClient.post<any>(this.check_program_url(url, data), this.check_program_data(url, data), {headers,responseType: 'json'}).pipe(catchError(this.handleError))
  }
  // delete
  // put

   // Error handling 
  handleError(error) {
      let errorMessage = '';
      if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(errorMessage);
  }

  check_program_url(url , data?){
    // get program id for LS
    let programdetails:any;
    programdetails = JSON.parse(window.localStorage.getItem("login-details"));
    // If it is not the API that is used to change the program_id
    if(url != this.config.loginUrl){
      // add program id if not exists
      // replace program_id if already exists and is wrong, reason: some places have hardcoded values
      try {
        let check_q_mark = url.indexOf('?');
        if(check_q_mark != -1){
          let last_char = url.charAt(url.length - 1);
          let program = url.indexOf("program_id=1");
          if(program != -1){
           url = url.replace("program_id=1",("program_id="+programdetails.program_id));
          }else{
            let program = url.indexOf("program_id=");
            if(program != -1){
              url = url.replace("program_id=",("program_id="+programdetails.program_id));
            }else{
              if(last_char != '/'){
                url += "&program_id="+programdetails.program_id;
              }
            }
            // console.log("&& : ",url)
          }
        }else{
          url += "?program_id="+programdetails.program_id;
        }
      } catch (error) {    }
      // if it is a post request, meaning if it has a body
      
    }
    return url;
  }
  check_program_data(url, data){
    let programdetails:any;
    programdetails = JSON.parse(window.localStorage.getItem("login-details"));
    if(programdetails){
      if(data){
        // if it is login API [inbuild method for Yii] 
        if(url == this.config.loginUrl){
          // console.log('here', typeof data );
          // data = JSON.parse(data)
          // data.LoginForm.program_id = programdetails.program_id
        } else{
          // every other post request
          data.program_id = programdetails.program_id
        }
  
      }
    }else{
      data.program_id = '';
    }
    
    return data;
  }
}
