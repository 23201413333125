/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: 'root',
})
export class ApiConfiguration {
  // baseUrl ='http://staging15api.bigcityexperiences.co.in';
  // STAGING DEV 
  baseUrlNew = 'https://appapi.protinex.bigcityvoucher.co.in/v1';
  // PROD
  //  baseUrlNew = 'https://appapi.protinex.in/v1'  

  // SISA
  // baseUrlNew = "https://sisa.app.api.bigcityvoucher.co.in/v1"

  //onboarding
  logout = this.baseUrlNew + '/user/logout'
  loginUrl = this.baseUrlNew + '/user/login';
  otpUrl = this.baseUrlNew + '/user/verifyotp';
  getLangauges = this.baseUrlNew + "/user/getlanguages?program_id=";
  setLanguage = this.baseUrlNew + "/user/langupdate";
  setPassword = this.baseUrlNew + '/user/set-password'
  verifyPassword = this.baseUrlNew + '/user/verifypassword'
  getTermsAndWelcomeVideo = this.baseUrlNew + '/user/getcontents';
  firstTimeLoginFlags = this.baseUrlNew + '/user/postfirstuserdata'
  ebrochureGetAll = this.baseUrlNew + '/brochure/get-brochure'

  //new
  upi_verification = this.baseUrlNew + '/enrollment/upi-penny-test?program_id='
  account_verification = this.baseUrlNew + '/enrollment/postbankdetails?program_id='
  userDetails = this.baseUrlNew + '/user/populate-userdetails?program_id=&role_id='


  getPrograms = this.baseUrlNew + '/admin/program?program_id='
  getEnrolledRoles = this.baseUrlNew + '/enrollment/self-enrolled-roles'
  getSelfEnrollFields = this.baseUrlNew + '/enrollment/list-role-fields?role_id='
  selfEnrollmentRole = this.baseUrlNew + '/enrollment/create-user'
  AadharValidation = this.baseUrlNew + '/enrollment/verify-aadhaar'
  submitAardharDetails = this.baseUrlNew + '/enrollment/verify-aadhaar-otp'

  //leaderBoard
  getListLeaderBoard = this.baseUrlNew + "/user/leaderboard?";

  //app info
  getAppInfo = this.baseUrlNew + '/user/get-app-info'
  userConfig = this.baseUrlNew + '/user/getuserconfiguration?program_id='
  // channel
  getChannels = this.baseUrlNew + '/user/get-channels'
  //enrollment
  getBeats = this.baseUrlNew + '/user/getbeats?program_id=';
  getOutletsOnBeat = this.baseUrlNew + '/user/outletuserlist?program_id=' + '&enrollment_status=&beat_id=';
  getSingleOutlet = this.baseUrlNew + '/user/getoutletbyid?program_id=' + '&user_id=';
  enrollOutlet = this.baseUrlNew + '/user/appenrollment';
  enrollOnlineBrochure = this.baseUrlNew + '/user/online-enrollment';
  getReasons = this.baseUrlNew + '/user/challenge-reasons?program_id=';
  challengeRejection = this.baseUrlNew + '/user/challenge-rejection';
  newSelfEnrollment = this.baseUrlNew + '/user/self-enrollment';
  getSupervisorOnCity = this.baseUrlNew + '/user/citywise-fse-list?city_id=';
  getEnrollmentStatus = this.baseUrlNew + '/user/self-enrollment-details?user_id=';
  selfEnrollmentReferral = this.baseUrlNew + '/enrollment/user-by-phone'
  influncerEnrollment = this.baseUrlNew + '/user/enroll-influencer'
  influncerEnrollmentOtp = this.baseUrlNew + '/user/verify-influencerotp'
  getEnrolledInfluncers = this.baseUrlNew + '/user/getinfluencerby-outlet'

  //Canon DEMO
  checkQrStatus = this.baseUrlNew + '/scan-earn/qr-status'
  submitCode = this.baseUrlNew + '/scan-earn/qr-submit'
  createTicket = this.baseUrlNew + '/carton/create-ticket'
  getTickets = this.baseUrlNew + '/carton/get-tickets'
  getQrEarnings = this.baseUrlNew + '/scan-earn/get-qr-submissions'
  // Common
  getStateList = this.baseUrlNew + '/user/getstatelist';
  getCityList = this.baseUrlNew + '/user/getcitylist';
  // *********************************WHITE LABEL****************************************
  pointsEarned = '/v1/user/mywinnings?program_id=';
  profileDetails = this.baseUrlNew + '/user/myprofile?program_id=';
  profileUpdate = '/user/updateprofile';
  //common
  getAllRegions = this.baseUrlNew + '/v1/user/get-all-regions?program_id=';
  getStateBasedOnRegion = this.baseUrlNew + '/v1/user/get-regions-based-state?program_id=' + '&region_id=';
  getCityBasedOnState = this.baseUrlNew + '/v1/user/getcitylistbystate?state_id=';
  getDistributorsOnRegion = this.baseUrlNew + '/v1/user/get-regions-based-distributor?program_id=' + '&region_id=';
  //By default the following API will return outlet modules
  getAllModules = this.baseUrlNew + '/v1/user/get-module-list?program_id=' + '&user_role_id='
  // rewards page
  rewardsCategory = this.baseUrlNew + '/reward/categories?rewardtype=';
  rewardsSubCategory = this.baseUrlNew + '/reward/sub-categories?category_id=';
  productlistbycategory = '/v1/reward/productlistbycategory?program_id=' + '&category_id=';

  productdetailsbyproduct = '/v1/reward/productdetails?program_id=' + '&product_id=';
  rewardsViewcart = '/v1/reward/viewcart?program_id=';
  rewardsCheckout = '/v1/reward/checkout?program_id=';
  deletecartbyproduct = '/v1/reward/deletecartbyprod';
  savecart_rewards = '/v1/reward/savecart';
  placeToOrder = '/v1/reward/orders'
  rewardHistory = '/v1/reward/orderhistory?program_id='
  getRewardOtp = this.baseUrlNew + '/reward/otp-orderaccesstoken-generation?program_id=';
  verifyRewardOtp = this.baseUrlNew + '/reward/verify-otp'
  //Visit tracker AKA TSE
  leaderboard = this.baseUrlNew + '/user/leaderboard?program_id=';
  vtDashboard = '/v1/tracking/visittrackerbytse?program_id=';
  vtTasks = '/v1/tracking/gettseoutlettasks?program_id=';
  vtReschedule = '/v1/tracking/rescheduleapi';
  vtOrderCategories = '/v1/tracking/productcategories?program_id=';
  vtCategoryProducts = '/v1/tracking/gettseproductlist?program_id=';
  vtTaskSubmit = this.baseUrlNew + '/v1/tracking/visit-tracker-transaction';
  vtOrderHistory = '/v1/ecommerce/orderhistory?program_id=';
  getAllOrders = this.baseUrlNew + '/invoice/get-all-eorders?program_id=';
  uploadOrders = this.baseUrlNew + '/invoice/upload-orders-invoice';

  enterInvoiceDetails = this.baseUrlNew + '/invoice/upload-orders-invoice-somphy'
  //enrollment
  preloadData = '/v1/enrollment/getpreloaddata?program_id=';
  enrollmentList = '/v1/enrollment/outletstatistics?program_id=';
  validateOTP = this.baseUrlNew + '/v1/enrollment/enrollmentvalidateotp';
  newUserEnrollment = this.baseUrlNew + '/v1/enrollment/userenrollment'

  //validations of pan and bank

  panValidation = this.baseUrlNew + '/enrollment/verify-pancard?program_id='
  pennyTest = this.baseUrlNew + '/enrollment/penny-test?program_id='
  gstIn = this.baseUrlNew + '/enrollment/gst-validation'
  //Visual Merchandiser
  visualTasks = '/v1/visualmerchandiser/getoutletlist?program_id=';
  getmonthwisevisit_visualmerchandiser = '/v1/visualmerchandiser/getmonthwisevisit?program_id=' + '&outlet_user_id=';
  getauditcount_visualmerchandiser = '/v1/visualmerchandiser/getauditcount?program_id=' + '&merchandiser_config_id=';
  getauditlist_visualmerchandiser = '/v1/visualmerchandiser/getauditlist?program_id=' + '&audit_count=';
  visualBrandList = '/v1/visualmerchandiser/getbrandselect?program_id=';
  visualAuditSubmit = '/v1/visualmerchandiser/visualtrans';
  // //eCommerce
  // eDashboardDetails = '/ecommerce/showhomepageoffer?program_id=';
  // eOfferDetails = '/ecommerce/productdetailsbyoffers?program_id=';
  // eAddToCart = '/ecommerce/savecart';
  // eCartDetails = '/ecommerce/viewcart?program_id=';
  // eRemoveFromCart = '/ecommerce/deletecartbyprod';
  // eGetAllAddress = '/ecommerce/selectdeliveryaddress?program_id=';
  // eAddAddress = '/ecommerce/addshippingaddress';
  // eCheckoutDetails = '/ecommerce/checkout?program_id=';
  // eOrder = '/ecommerce/orders';
  // clearCartCommon = '/ecommerce/clearcart';
  // eUpdateCartQuantity = '/ecommerce/update-quantity-cart-product'
  //distribtor
  getSchemes = this.baseUrlNew + '/user/outletuserlist?program_id=' + '&enrollment_status=&beat_id=';
  outletList = this.baseUrlNew + '/loyalty/schemes?program_id=' + '&user_id=';
  orderStatus = '/v1/ecommerce/loadorderdetailsbyuser?program_id=';
  gettsedistributorByoffer = '/v1/tracking/gettsedistributordetails?program_id=' + '&offer_id=';
  getordertseschemes = '/v1/tracking/getordertseschemes?program_id=' + '&category_id=';
  // content & Learning
  coursedetails = '/content/coursedetails?program_id=';
  courseQuize = '/content/coursequiz?program_id=' + '&course_id=';
  submitCoursequiz = '/content/docoursequiz';
  orderStatusDetails = '/ecommerce/getorderdetails?program_id=';
  processOrders = '/ecommerce/processorders';
  invoiceOutletList = '/ecommerce/loadoutletdetails?program_id=';
  loadOrderInvoice = '/ecommerce/loadoutletorderdetails?program_id=';
  attachInvoice = '/ecommerce/attachinvoice';
  viewAllInvoice = '/ecommerce/viewinvoices?program_id=';
  //couponUniqueCodeSend = '/scan-earn/get-scan-earn?program_id=';
  // Engagement Apis
  EngageTaskDetails = '/v1/task/tasklist?program_id=';
  engageGetquizactivity = this.baseUrlNew + '/task/getquizactivity?program_id=' + '&';
  engagementSubmitData = this.baseUrlNew + '/task/dotaskactivity';
  tasklist = this.baseUrlNew + '/task/tasklist?program_id='
  //common
  getNotification = '/v1/notification/viewnotification';
  markNotificationAsComplete = '/v1/notification/readnotification';
  //SCAN
  // Carton insert
  getCartonCampaigns = this.baseUrlNew + '/carton/cartondashboard?campaign_id=';
  scanCartons = this.baseUrlNew + '/carton/cartonachievements';
  // Carton API by ALI, remove crap when refining
  couponTypes = '/carton/cartonselect';
  couponScan = '/carton/cartonqrcode';
  couponUniqueCode = '/carton/cartonunique';
  couponWinnings = '/carton/cartonwinnings';
  couponUniqueCodeSend = '/scan-earn/get-scan-earn?program_id=';
  influencerBarcodeScan = '/scan-earn/earn-point?program_id=';
  //Visibility
  visibilityModules = this.baseUrlNew + '/pointofsale/visibility?program_id=';
  posmTasks = this.baseUrlNew + '/pointofsale/posmauditlist?program_id=';
  visibilityTasksListNew = this.baseUrlNew + '/pointofsale/posm-audit-element-list?program_id=' + '&'
  posmPostTask = this.baseUrlNew + "/pointofsale/posmtrans";
  doamdonecourse = "/content/doamdonecourse";
  getPosmTaskElements = '/v1/posm-audit-element-list?program_id=' + '&visibility_id=1&posm_master_config_id='
  //target & Achievement
  getTargetsAndAchivements = '/v1/loyalty/targetvsachievements?program_id=';
  GetCurrentWOF = '/v1/task/currentwof?program_id=';
  GetWOF = '/v1/task/wof?program_id=';
  target_dashboard = this.baseUrlNew + "/loyalty/target-achieved-stats?program_id="
  target_tracker = this.baseUrlNew + "/loyalty/outlet-tracker?program_id="
  target_performance = this.baseUrlNew + "/loyalty/outlet-target-achieved-stats?program_id=";

  getListTargtesList = this.baseUrlNew + "/target/get-target-and-achievement-csm";

  //Rewards
  productquantityupdate = "/v1/reward/productquantityupdate"
  citylist = "/v1/user/getcitylistbystate?state_id=";
  statelist = "/v1/user/getstatelist";
  shipping_cart = this.baseUrlNew + '/reward/addshippingaddress';
  physicalmerchandisebrands = '/v1/reward/physicalmerchandisebrands?program_id=' + '&category_id=';
  getphysicalproductdetails = '/v1/reward/getphysicalproductdetails?program_id=' + '&product_id=';
  physicalmerchandiseproducts = '/v1/reward/physicalmerchandiseproducts?program_id=' + '&brand_id='
  physicalmerchandisecategory = '/v1/reward/physicalmerchandisecategory?program_id=' + '&category_id=';
  trackHistory = '/v1/reward/ordertrackingphysicalrewards?program_id=' + '&order_id=';
  getCategories = "/loyalty/product-category?program_id=";
  getProductsBasedOnCategory = "/loyalty/product-points?program_id=";
  getInvoice_details = '/invoice/get-all-invoices?program_id=' + '&month_year=';
  reupload_invoice = "/invoice/re-upload-invoice";
  get_all_rewards_products = '/reward/productlisthome?program_id=';
  getProductsOnFilter = this.baseUrlNew + '/reward/productlistbycategory?'
  getBrandProductDrop = this.baseUrlNew + '/reward/get-brands-list?'
  get_reward_denaminations = '/reward/productdetails?program_id=';
  reward_save_to_cart = '/reward/savecart';
  view_reward_view_cart = '/reward/viewcart?program_id=';
  remove_reward_from_cart = '/reward/deletecartbyprod';
  reward_order_confirm = '/reward/orderconfirm?program_id=';
  rewards_checkout = "/reward/checkout?program_id=";
  rewards_place_orders = "/reward/orders";
  rewards_history = "/reward/orderhistory?program_id=";
  reward_order_voucher_details = this.baseUrlNew + '/reward/getorderoffercode?program_id=' + '&order_access_token=';

  //Point Ledger 
  pointLedger = this.baseUrlNew + "/loyalty/user-points-summary?program_id=";

  //approval

  getRoles = this.baseUrlNew + '/enrollment/get-child-roles?program_id='
  getEnrollRequest = this.baseUrlNew + '/enrollment/get-userdetails?program_id='
  getheader = this.baseUrlNew + '/enrollment/get-user-header?program_id='
  approveRequest = this.baseUrlNew + '/enrollment/approve-reject-enrollment?program_id='

  //Dashboard
  moduleApi = this.baseUrlNew + "/user/getmodules?program_id="

  CanEnrolls = this.baseUrlNew + "/user/can-enroll?program_id="
  otpForm = this.baseUrlNew + "/enrollment/sendotp?program_id="


  //notification
  notificationData = this.baseUrlNew + "/notification/getnotification"
  notificationRead = this.baseUrlNew + "/notification/notificationread?notification_id=";
  profile_roles = this.baseUrlNew + '/user/get-outlet-roles?profile_code=RTLR';

  // tasks
  getContestList = this.baseUrlNew + "/task/contestlist";
  getContestById = this.baseUrlNew + "/task/contestlistbyid?id=";
  saveContest = this.baseUrlNew + "/task/savecontest";

  // invoice status
  getInvoiceList = this.baseUrlNew + "/invoice/get-all-invoices-somphy?program_id=";

  // get address
  getAddressList = this.baseUrlNew + "/reward/get-shipping-address-by-user?program_id=";
  updateShippingAddress = this.baseUrlNew + "/reward/update-shipping-address";

  // get poll questions
  getQuestionList = this.baseUrlNew + "/task/get-poll-survey-by-id?program_id=" + "&contest_id=";
  submitQuestion = this.baseUrlNew + "/task/poll-survey-submission?program_id=";
  // getSubmittedQuestion = this.baseUrlNew + "/task/get-submited-poll-survey?program_id=";

  // hsc product list
  getProductList = this.baseUrlNew + "/task/get-product-list?program_id=" + "&contest_id=";

  // ml api for cooler and shelg
  coolercount = "http://35.207.237.21:5000/maskImage"
  shelfcount = "http://35.207.237.21:5001/maskImage"
}
export interface ApiConfigurationInterface {
  baseUrl?: string,
  baseUrlNew?: string,
  logInUrl?: string
}