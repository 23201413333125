import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ToasterService } from '../toaster/toaster.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {

  constructor(private router: Router,private toaster:ToasterService, public auth: AuthenticationService) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let authToken = this.auth.isAuthenticated();
  //  let authToken =  window.localStorage.getItem('auth-token');
    let pagePath = state.url;

    let checkLogin = pagePath.search('mobile-number');
    let checkOtp =  pagePath.search("/otp");
    let checkVideo =  pagePath.search("/welcome-video");
    let checkTerms =  pagePath.search("terms-and-conditions");
    let checkPassword =  pagePath.search("/enter-password");
    let checkSetPassword =  pagePath.search("/create-password");
    // console.table( [ {'Auth':authToken,'mobile-number':checkLogin,'enter-password':checkOtp}]);
    console.table( [ {'Auth':authToken,'checkLogin':checkLogin,'checkOtp':checkOtp, 'checkVideo': checkVideo,'checkTerms': checkTerms,'checkPassword': checkPassword,'checkSetPassword': checkSetPassword, 'pagePath': pagePath}]);
    window.localStorage.setItem('pageUrl',state.url);
      if (authToken) {
          // logged in so return true
          if(checkLogin !== -1) return false;
          if(checkOtp !== -1) return false;
          if(checkVideo !== -1) return false;
          if(checkTerms !== -1) return false;
          if(checkPassword !== -1) return false;
          if(checkSetPassword !== -1) return false;

          return true;
      }
        
          if(checkLogin !== -1) return true;
          if(checkOtp !== -1) return true;
          if(checkVideo !== -1) return true;
          if(checkTerms !== -1) return true;
          if(checkPassword !== -1) return true;
          if(checkSetPassword !== -1) return true;

        this.toaster.presentToast("Please login!");
      
      // not logged in so redirect to login page with the return url and return false
      // this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
      return false;
  }
}
