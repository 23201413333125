import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ConfirmAlertService {

  constructor(public alertController: AlertController) { }

  async presentAlert(data) {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        cssClass: 'confirm_alert',
        header: data,
        buttons: [, {
            text: 'Ok',
            handler: (ok) => {
              resolve('ok');
            }
          }
        ]
      });
      alert.present();
    });
  }
}
